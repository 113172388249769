import { Button } from "antd";
import { useHistory } from "react-router-dom";

import logo from "./../../assets/images/logo-horizontal.png";

import classes from "./home-form.module.scss";

const HomeForm = () => {
  const history = useHistory();

  const year = new Date().getFullYear();

  const onStart = () => {
    history.push("/ubicacion");
  };

  return (
    <div className={classes.form}>
      <div className={classes.gradient}>
        <div className={classes.header}>
          <img src={logo} alt="Logo" className={classes.logo} />
        </div>

        <div className={classes.content}>
          <h1>Centro de Reclamos Municipales</h1>
          <p>
            Espacio destinado para Reclamos
            <br /> Municipales del ciudadano.
          </p>
          <Button type="primary" onClick={onStart}>
            Comenzar
          </Button>
        </div>

        <div className={classes.footer}></div>
      </div>
    </div>
  );
};

export default HomeForm;
