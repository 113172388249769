import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form } from "antd";
import axios from "axios";

import ReclamosContext from "../store/reclamos-context";

import ReclamoForm from "../components/reclamo/reclamo-form";
import Card from "../components/ui/card";
import Content from "../components/ui/content";
import Header from "../components/ui/header";
import Footer from "../components/ui/footer";
import Stepper from "../components/ui/stepper";

const ReclamoPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const ctx = useContext(ReclamosContext);
  const [loading, setLoading] = useState(false);

  const onBack = () => {
    history.push("/contacto");
  };

  const onSubmit = async () => {
    const valid = await form.validateFields();
    const values = form.getFieldValue();

    if (valid) {
      const data = {
        direccion: ctx.direccion,
        distrito: ctx.distrito,
        punto: ctx.punto,
        nombre: ctx.nombre,
        documento: ctx.documento,
        telefono: ctx.telefono,
        email: ctx.email,
        detalle: values.detalle,
      };

      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/micrositios/reclamos`,
          data
        );
        setLoading(false);

        if (response) {
          const id = response.data.id;
          history.push(`/finished?id=${id}`);
        } else {
          history.push("/error");
        }
      } catch (error) {
        history.push("/error");
      }
    }
  };

  return (
    <Card>
      <Header />
      <Content>
        <Stepper />
        <ReclamoForm form={form} alert={alert} />
      </Content>
      <Footer>
        <Button type="secondary" onClick={onBack}>
          Volver
        </Button>
        <Button type="primary" onClick={onSubmit} loading={loading}>
          {!loading && "Finalizar"}
        </Button>
      </Footer>
    </Card>
  );
};

export default ReclamoPage;
