import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { FiCheck, FiMapPin, FiMessageSquare, FiUser } from "react-icons/fi";

import classes from "./stepper.module.scss";

const Stepper = (props) => {
  const history = useHistory();

  const path = history.location.pathname.toLowerCase();

  const steps = [
    {
      id: 1,
      icon: <FiMapPin />,
      title: "1. Ubicación",
      description: "Confirmá tu ubicación",
      active: path === "/ubicacion",
      completed: path === "/contacto" || path === "/reclamo",
    },
    {
      id: 2,
      icon: <FiUser />,
      title: "2. Datos de Contacto",
      description: "Cargá tus datos de contacto",
      active: path === "/contacto",
      completed: path === "/reclamo",
    },
    {
      id: 3,
      icon: <FiMessageSquare />,
      title: "3. Reclamo",
      description: "Contanos cual es tu reclamo",
      active: path === "/reclamo",
      completed: false,
    },
  ];

  return (
    <div className={classes.stepper}>
      {steps.map((step, index) => (
        <Fragment key={step.id}>
          <div
            className={`${classes.step} 
            ${step.active ? classes.active : null} 
            ${step.completed ? classes.completed : null}`}
          >
            <div className={classes.icon}>
              {step.completed ? <FiCheck /> : step.icon}
            </div>
            <div>
              <div className={classes.title}>{step.title}</div>
              <div className={classes.description}>{step.description}</div>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default Stepper;
