import { createContext, useState } from "react";

const ReclamosContext = createContext({
  direccion: "",
  distrito: "",
  punto: "",
  nombre: "",
  documento: "",
  telefono: "",
  email: "",
  detalle: "",
  updateUbicacion: (direccion, distrito, punto) => {},
  updateContacto: (nombre, documento, telefono, email) => {},
  updateReclamo: (detalle) => {},
});

export function ReclamosContextProvider(props) {
  // State
  const [direccion, setDireccion] = useState();
  const [distrito, setDistrito] = useState();
  const [punto, setPunto] = useState();
  const [nombre, setNombre] = useState();
  const [documento, setDocumento] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState();
  const [detalle, setDetalle] = useState();

  // Methods
  const updateUbicacion = (direccion, distrito, punto) => {
    setDireccion(direccion);
    setDistrito(distrito);
    setPunto(punto);
  };

  const updateContacto = (nombre, documento, telefono, email) => {
    setNombre(nombre);
    setDocumento(documento);
    setTelefono(telefono);
    setEmail(email);
  };

  const updateReclamo = (detalle) => {
    setDetalle(detalle);
  };

  const context = {
    direccion: direccion,
    distrito: distrito,
    punto: punto,
    nombre: nombre,
    documento: documento,
    telefono: telefono,
    email: email,
    detalle: detalle,
    updateUbicacion: updateUbicacion,
    updateContacto: updateContacto,
    updateReclamo: updateReclamo,
  };

  return (
    <ReclamosContext.Provider value={context}>
      {props.children}
    </ReclamosContext.Provider>
  );
}

export default ReclamosContext;
