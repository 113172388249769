/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Card from "../components/ui/card";
import Content from "../components/ui/content";
import HomeForm from "../components/home/home-form";

import ReclamosContext from "../store/reclamos-context";

const HomePage = () => {
  const ctx = useContext(ReclamosContext);

  const params = new URLSearchParams(useLocation().search);
  const punto = params.get("punto");

  useEffect(() => {
    ctx.updateUbicacion("", "", punto ?? "");
    ctx.updateContacto("", "", "", "");
    ctx.updateReclamo("", "");
  }, []);

  return (
    <Card>
      <Content>
        <HomeForm />
      </Content>
    </Card>
  );
};

export default HomePage;
