import logo from "./../../assets/images/logo.png";

import classes from "./header.module.scss";

const Header = () => {
  return (
    <div className={classes.header}>
      <img src={logo} alt="Logo" className={classes.logo} />
      <div>
        <div className={classes.pretitle}>Municipalidad</div>
        <div className={classes.title}>Centro de Reclamos Municipales</div>
      </div>
    </div>
  );
};

export default Header;
