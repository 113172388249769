/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Col, Divider, Form, Input, Row, Select } from "antd";
import { FiAlertTriangle } from "react-icons/fi";

import ReclamosContext from "../../store/reclamos-context";

import classes from "./ubicacion-form.module.scss";

const UbicacionForm = (props) => {
  const { form, warning } = props;

  const ctx = useContext(ReclamosContext);

  const [puntos, setPuntos] = useState([]);
  const [distritos, setDistritos] = useState([]);

  useEffect(() => {
    async function getForm() {
      const rData = await axios.get(
        `${process.env.REACT_APP_API_URL}/micrositios/reclamos/data/puntos`
      );

      if (rData && rData.data.code === 0) {
        setDistritos(rData.data.data.distritos);
        setPuntos(rData.data.data.puntos);
      }

      form.setFieldsValue({
        punto: ctx.punto,
        direccion: ctx.direccion,
        distrito: ctx.distrito,
      });
    }

    getForm();
  }, []);

  const onChangeUbicacion = () => {
    form.resetFields(["punto"]);
  };

  const onChangePunto = (value) => {
    const punto = puntos.find((x) => x.id === value);
    form.setFieldsValue({
      direccion: punto?.direccion ?? "",
      distrito: punto?.distrito ?? "",
    });
  };

  return (
    <div className={classes.form}>
      {warning && (
        <div className="alert warning">
          <div className="icon">
            <FiAlertTriangle />
          </div>
          <div className="message">{warning}</div>
        </div>
      )}
      <Form form={form} layout="vertical">
        <div className={classes.title}>Carga la ubicación de tu reclamo</div>
        <Row gutter={20}>
          <Col xs={24} lg={16}>
            <Form.Item label="Dirección" name="direccion">
              <Input onChange={onChangeUbicacion} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Distrito" name="distrito">
              <Select
                showSearch
                optionFilterProp="children"
                onChange={onChangeUbicacion}
              >
                {distritos.map((x) => (
                  <Select.Option key={x.id} value={x.id.toString()}>
                    {x.nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <div className={classes.title}>
          O selecciona el punto de reclamo más cercano
        </div>
        <Row gutter={20}>
          <Col xs={24} lg={24}>
            <Form.Item name="punto">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={onChangePunto}
              >
                {puntos.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.descripcion}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UbicacionForm;
