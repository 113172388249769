import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import { ReclamosContextProvider } from "./store/reclamos-context";

import ErrorPage from "./pages/Error";
import UbicacionPage from "./pages/Ubicacion";
import ReclamoPage from "./pages/Reclamo";
import ContactoPage from "./pages/Contacto";
import HomePage from "./pages/Home";
import FinishedPage from "./pages/Finished";

import "./styles/index.scss";
import "./styles/alerts.scss";
import "./styles/buttons.scss";
import "./styles/datepicker.scss";
import "./styles/fonts.scss";
import "./styles/inputs.scss";
import "./styles/selects.scss";

ReactDOM.render(
  <ReclamosContextProvider>
    <div className="main-content">
      <Router>
        <Switch>
          <Route path="/error">
            <ErrorPage />
          </Route>
          <Route path="/finished">
            <FinishedPage />
          </Route>
          <Route path="/reclamo">
            <ReclamoPage />
          </Route>
          <Route path="/contacto">
            <ContactoPage />
          </Route>
          <Route path="/ubicacion">
            <UbicacionPage />
          </Route>
          <Route path="/" exact>
            <HomePage />
          </Route>
        </Switch>
      </Router>
    </div>
  </ReclamosContextProvider>,
  document.getElementById("root")
);
