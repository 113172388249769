import * as moment from "moment";

export const required = {
  required: true,
  message: "Este campo es obligatorio",
};

export const regex = (expression) => {
  return {
    pattern: expression.pattern,
    message: expression.message,
  };
};

export const parseYear = (value) => {
  return value === undefined || value === null
    ? undefined
    : value.format("YYYY");
};

export const parseToYear = (value) => {
  return value === undefined || value === null
    ? undefined
    : moment(value, "YYYY");
};
