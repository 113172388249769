import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form } from "antd";

import ReclamosContext from "../store/reclamos-context";

import Card from "../components/ui/card";
import Content from "../components/ui/content";
import Header from "../components/ui/header";
import Footer from "../components/ui/footer";
import Stepper from "../components/ui/stepper";
import UbicacionForm from "./../components/ubicacion/ubicacion-form";

const UbicacionPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const ctx = useContext(ReclamosContext);

  const [warning, setWarning] = useState();

  const onBack = () => {
    history.push("/");
  };

  const onNext = async () => {
    const valid = await form.validateFields();
    const values = form.getFieldValue();

    if (!values.punto && (!values.direccion || !values.distrito)) {
      setWarning(
        "Tenés que ingresar una dirección con su distrito o seleccionar un punto de reclamo para continuar."
      );
      return;
    }

    setWarning(undefined);

    if (valid) {
      ctx.updateUbicacion(values.direccion, values.distrito, values.punto);
      history.push("/contacto");
    }
  };

  return (
    <Card>
      <Header />
      <Content>
        <Stepper />
        <UbicacionForm form={form} warning={warning} />
      </Content>
      <Footer>
        <Button type="secondary" onClick={onBack}>
          Volver
        </Button>
        <Button type="primary" onClick={onNext}>
          Siguiente
        </Button>
      </Footer>
    </Card>
  );
};

export default UbicacionPage;
