import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form } from "antd";

import ReclamosContext from "../store/reclamos-context";

import ContactoForm from "../components/contacto/contacto-form";
import Card from "../components/ui/card";
import Content from "../components/ui/content";
import Header from "../components/ui/header";
import Footer from "../components/ui/footer";
import Stepper from "../components/ui/stepper";

const ContactoPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const ctx = useContext(ReclamosContext);

  const onBack = () => {
    history.push("/ubicacion");
  };

  const onNext = async () => {
    const valid = await form.validateFields();
    const values = form.getFieldValue();

    if (valid) {
      ctx.updateContacto(
        values.nombre,
        values.documento,
        values.telefono,
        values.email
      );
      history.push("/reclamo");
    }
  };

  return (
    <Card>
      <Header />
      <Content>
        <Stepper />
        <ContactoForm form={form} alert={alert} />
      </Content>
      <Footer>
        <Button type="secondary" onClick={onBack}>
          Volver
        </Button>
        <Button type="primary" onClick={onNext}>
          Siguiente
        </Button>
      </Footer>
    </Card>
  );
};

export default ContactoPage;
