import { Button } from "antd";
import { useHistory } from "react-router-dom";
import useQuery from "./../hooks/useQuery";

import Card from "./../components/ui/card";
import Content from "../components/ui/content";
import Header from "../components/ui/header";

import icon from "./../assets/images/email.svg";

const FinishedPage = () => {
  const history = useHistory();

  debugger;
  const query = useQuery();
  const id = query.get("id");

  const onBack = () => {
    history.push("/");
  };

  return (
    <Card>
      <Header />
      <Content>
        <div className="status">
          <img src={icon} alt="Not Found" />
          <h1>
            Tu reclamo con número <b>#{id}</b> fue recibido.
          </h1>
          <p>
            Muchas gracias por utilizar los canales digitales de la Municipalidad
          </p>
          <Button type="secondary" onClick={onBack}>
            Volver
          </Button>
        </div>
      </Content>
    </Card>
  );
};

export default FinishedPage;
