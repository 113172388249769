/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { Col, Form, Input, Row } from "antd";
import { required } from "./../../services/forms";

import ReclamosContext from "../../store/reclamos-context";

import classes from "./reclamo-form.module.scss";

const ReclamoForm = (props) => {
  const { form } = props;

  const ctx = useContext(ReclamosContext);

  useEffect(() => {
    async function getForm() {
      form.setFieldsValue({
        detalle: ctx.detalle,
      });
    }

    getForm();
  }, []);

  return (
    <div className={classes.form}>
      <Form form={form} layout="vertical">
        <Row gutter={20}>
          <Col xs={24} lg={24}>
            <Form.Item label="Detalle" name="detalle" rules={[required]}>
              <Input.TextArea showCount maxLength={2000} rows={7} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ReclamoForm;
