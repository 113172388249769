/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

import ReclamosContext from "../store/reclamos-context";

import Card from "./../components/ui/card";
import Content from "../components/ui/content";
import Header from "../components/ui/header";

import icon from "./../assets/images/warning.svg";

const ErrorPage = () => {
  const history = useHistory();

  const ctx = useContext(ReclamosContext);

  useEffect(() => {
    ctx.updatePunto("");
    ctx.updateContacto("", "", "", "");
    ctx.updateReclamo("", "");
  }, []);

  const onBack = () => {
    history.push("/");
  };

  return (
    <Card>
      <Header />
      <Content>
        <div className="status">
          <img src={icon} alt="Not Found" />
          <h1>¡Ups! Parece que ocurrió un error</h1>
          <p>
            Se produjo un error inesperado de nuestra parte, por favor reintentá
            nuevamente.
          </p>
          <Button type="secondary" onClick={onBack}>
            Volver
          </Button>
        </div>
      </Content>
    </Card>
  );
};

export default ErrorPage;
