/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { Col, Form, Input, Row } from "antd";

import ReclamosContext from "../../store/reclamos-context";
import { regex, required } from "./../../services/forms";

import classes from "./contacto-form.module.scss";

const ContactoForm = (props) => {
  const { form } = props;

  const ctx = useContext(ReclamosContext);

  const regexNombre = {
    pattern: /^[a-zA-ZñÑ´áéíóúÁÉÍÓÚ ,.'-]+$/g,
    message: "El nombre solo puede contener letras y espacios",
  };

  const regexDocumento = {
    pattern: /^\d{7,8}$/g,
    message: "El documento debe contener entre 7 y 8 dígitos",
  };

  const regexTelefono = {
    pattern: /^\d{10}$/g,
    message: "El teléfono debe contener 10 dígitos",
  };

  const regexEmail = {
    pattern:
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g,
    message: "El email ingresado no es una dirección de correo válida",
  };

  useEffect(() => {
    form.setFieldsValue({
      nombre: ctx.nombre,
      documento: ctx.documento,
      telefono: ctx.telefono,
      email: ctx.email,
    });
  }, []);

  return (
    <div className={classes.form}>
      <Form form={form} layout="vertical">
        <Row gutter={20}>
          <Col xs={24} lg={24}>
            <Form.Item
              label="Nombre y Apellido"
              name="nombre"
              rules={[required, regex(regexNombre)]}
            >
              <Input placeholder="Juan Perez" autoFocus />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              label="Documento"
              name="documento"
              rules={[required, regex(regexDocumento)]}
            >
              <Input placeholder="12345678" />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              label="Teléfono"
              name="telefono"
              rules={[required, regex(regexTelefono)]}
            >
              <Input placeholder="2612345678" />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              label="Correo Electrónico"
              name="email"
              rules={[required, regex(regexEmail)]}
            >
              <Input placeholder="nombre@email.com" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ContactoForm;
